<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" inline label-position="left" label-width="0" size="small ">
      <el-form-item label="">
        <el-select
          v-model="form.platform"
          clearable
          filterable
          placeholder="平台"
        >
          <el-option
            v-for="item in platformList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input
          v-model.trim="form.orderNum"
          clearable
          placeholder="订单号"
          @keyup.native.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.warehouse"
          clearable
          filterable
          multiple
          placeholder="仓库"
          reserve-keyword
        >
          <el-option
            v-for="item in warehouseList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.carrierCode"
          clearable
          filterable
          placeholder="物流商"
        >
          <el-option
            v-for="item in carrierCodeList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-select
          v-model="form.status"
          clearable
          filterable
          placeholder="物流状态"
        >
          <el-option
            v-for="item in statusList"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-input
          v-model.trim="form.trackingNumber"
          clearable
          placeholder="物流跟踪号"
        />
      </el-form-item>
      <el-form-item label=" " prop="date" style="white-space: nowrap">
        <el-select v-model="form.dateType" filterable style="width: 100px;margin-right: 5px">
          <el-option
            v-for="item in dateTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <el-date-picker
          v-model="form.date"
          :clearable="false"
          :default-time="['00:00:00', '23:59:59']"
          :editable="false"
          align="center"
          class="time-range-picker"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm:ss"
          range-separator="~"
          start-placeholder="开始日期"
          type="datetimerange"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="">
        <el-checkbox v-model="form.onlineTimeLimitGtTwo">
          上网时效 > 2
        </el-checkbox>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox v-model="form.timeSensitiveGtSeven">
          签收时效 > 7
        </el-checkbox>
      </el-form-item>
      <el-form-item label="">
        <el-checkbox v-model="form.isRemotePostcode">
          偏远地区
        </el-checkbox>
      </el-form-item>
      <el-form-item style="white-space: nowrap">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <el-button :loading="exportLoading" size="small" type="primary" @click="handleExport">导出</el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :column-config="{ resizable: true }"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      header-row-class-name="table-header"
    >
      <vxe-column field="platform" title="平台" />
      <vxe-column field="orderNum" title="订单号" />
      <vxe-column field="trackingNumber" title="物流跟踪号" />
      <vxe-column field="carrierCode" title="物流商" />
      <vxe-column field="status" title="物流状态" width="130" />
      <vxe-column field="warehouse" title="仓库" />
      <vxe-column field="infoReceivedUtcTime" title="发货时间" />
      <vxe-column field="inTransitUtcTime" title="上网时间" />
      <vxe-column field="deliveredUtcTime" title="签收时间" />
      <vxe-column field="onlineTimeLimit" title="上网时效" width="80" />
      <vxe-column field="timeSensitive" title="签收时效" width="80" />
      <vxe-column field="sendQuantity" title="发货数量" width="80" />
      <vxe-column field="isRemotePostcode" title="偏远地区" width="80">
        <template #default="{row}">
          <span :style="{color: row.isRemotePostcode === '是' ? 'red' : ''}">
            {{ row.isRemotePostcode }}
          </span>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
  </div>
</template>

<script>
import Paging from '@/components/Paging'
import { getLogisticsEnum, getLogisticsInfo } from '@/api/omsorder'
import { debounceGetTableMaxHeight, exportSuccess } from '@/utils'
import { unifiedExport } from '@/api/a-base-host'
import dayjs from 'dayjs'

export default {
  name: 'SelfDeliveryLogisticsInformation',
  components: {
    Paging
  },
  data() {
    return {
      form: {
        orderNum: '',
        warehouse: [],
        carrierCode: '',
        status: '',
        trackingNumber: '',
        isRemotePostcode: '',
        dateType: '1',
        date: [dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
        onlineTimeLimitGtTwo: false,
        timeSensitiveGtSeven: false
      },
      rules: {
        date: [
          {
            required: true,
            message: this.$t('page.selectPlaceholder'),
            trigger: 'change'
          }
        ]
      },
      dateTypeList: [],
      platformList: [],
      carrierCodeList: [],
      statusList: [],
      warehouseList: [],
      queryLoading: false,
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      tableData: [],
      maxHeight: 520,
      exportLoading: false
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        startTime: this.form.date?.[0],
        endTime: this.form.date?.[1],
        pageNo: this.pager.current,
        pageSize: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.getEnums()
    this.handleQuery()
  },
  mounted() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this, {
      offsetBottom: 80
    })
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async getEnums() {
      const { datas } = await getLogisticsEnum()
      this.carrierCodeList = datas?.carrierCode || []
      this.statusList = datas?.status || []
      this.warehouseList = datas?.warehouse || []
      if (datas?.dateType) {
        this.dateTypeList = Object.keys(datas.dateType).map(key => ({ label: datas.dateType[key], value: key }))
      } else {
        this.dateTypeList = []
      }
      this.platformList = datas?.platform || []
    },
    handleQuery() {
      this.$refs.form.validate(async(valid) => {
        if (!valid) {
          return
        }
        if (this.queryChange) {
          this.pager.current = 1
        }
        this.queryLoading = true
        const { datas } = await getLogisticsInfo(this.queryParams).finally(() => {
          this.queryLoading = this.queryChange = false
          this.tableData = []
        })
        const { records, pager } = datas || {}
        this.tableData = records || []
        this.pager.total = pager?.total || 0
      })
    },
    handleReset() {
      this.form = {
        warehouse: [],
        carrierCode: '',
        status: '',
        trackingNumber: '',
        dateType: '1',
        date: [dayjs().subtract(7, 'day').startOf('day').format('YYYY-MM-DD HH:mm:ss'), dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss')],
        onlineTimeLimitGtTwo: false,
        timeSensitiveGtSeven: false
      }
    },
    async handleExport() {
      // 校验查询条件值是否不为空
      const isNotEmpty = Object.values(this.form).some(item => {
        if (Array.isArray(item)) {
          return item.length !== 0
        }
        return item !== '' && item !== undefined && item !== null
      })
      if (isNotEmpty) {
        this.exportLoading = true
        const params = {
          appName: 'oms',
          exportType: 'track-info',
          reqParam: JSON.stringify(this.queryParams)
        }
        await unifiedExport(params).finally(() => {
          this.exportLoading = false
        })
        exportSuccess()
      } else {
        this.$message.warning('请填写查询条件')
      }
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>
.table-header {
  position: sticky;
}

::v-deep .el-button {
  min-width: 80px;
}

::v-deep .vxe-cell--title, ::v-deep .vxe-cell--label {
  word-break: break-word;
}

::v-deep .el-date-editor--daterange.el-input__inner {
  max-width: 350px;
  width: auto;
}
</style>
